// app/javascript/packs/application.js
import ReactOnRails from "react-on-rails";
import HelloWorld from "../bundles/HelloWorld/components/HelloWorld";
import HelloWorldApp from "../startup/HelloWorldApp";
import Chat from "../bundles/Chat/components/Chat";
import ChatList from "../bundles/Chat/components/ChatList";
import StoryTellingChat from "../bundles/Chat/components/StoryTellingChat";
import ImageSection from "../bundles/Chat/components/ImageSection";
import ImageCreation from "../bundles/Chat/components/ImageCreation";
import ImageGrid from "../bundles/Shared/components/ImageGrid";
import ImageMosaic from "../bundles/Shared/components/ImageMosaic";
import StripeWrapper from "../bundles/Shared/components/StripeWrapper";
import Spinner from "../bundles/Chat/components/Spinner";
import UserMessage from "../bundles/Chat/components/UserMessage";
import AssistantMessage from "../bundles/Chat/components/AssistantMessage";
import Listeners from "./listeners";
import LogrocketWrapper from "../bundles/Shared/components/LogrocketWrapper";

ReactOnRails.register({
  HelloWorld,
  HelloWorldApp,
  Chat,
  ChatList,
  StoryTellingChat,
  ImageSection,
  ImageCreation,
  ImageGrid,
  ImageMosaic,
  StripeWrapper,
  Spinner,
  Listeners,
  UserMessage,
  AssistantMessage,
  LogrocketWrapper
});
