import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';

const HeartIcon = ({ filled }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={filled ? "red" : "none"} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
  </svg>
);

const Lightbox = ({ image, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={onClose}>
      <div className="max-w-4xl max-h-full p-4">
        <img src={image.imageUrl} alt="User generated" className="max-w-full max-h-[90vh] object-contain" />
        <div className="mt-4 text-white">
          <p className="text-lg font-bold">{image.createdBy}</p>
          <p className="text-sm">{image.created_at}</p>
        </div>
      </div>
    </div>
  );
};

const ImagePlaceholder = () => (
  <div className="w-full h-0 pb-[100%] bg-gray-200 animate-pulse"></div>
);

const ImageCell = React.memo(({ image, onClick, onHeartToggle, signedIn }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  if (!image) return <ImagePlaceholder />;

  return (
    <div className="relative group cursor-pointer aspect-square" onClick={() => onClick(image)}>
      <div className={`absolute inset-0 ${imageLoaded ? 'hidden' : 'block'}`}>
        <ImagePlaceholder />
      </div>
      <img 
        src={image.imageUrl} 
        alt="User generated" 
        className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
        onLoad={() => setImageLoaded(true)}
        loading="lazy"
      />
      {imageLoaded && (
        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-60 transition-opacity duration-300">
          <div className="absolute top-2 left-2">
            <img 
              src={image.creatorAvatarUrl} 
              alt={`${image.createdBy}'s avatar`} 
              className="w-8 h-8 rounded-full border-2 border-white"
            />
          </div>
          <div className="absolute bottom-2 left-2 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <p className="text-sm font-bold">{image.createdBy}</p>
            <p className="text-xs">{image.created_at}</p>
          </div>
          {signedIn && (
            <div 
              className="absolute top-2 right-2 bg-white bg-opacity-75 rounded-full p-1"
              onClick={(e) => onHeartToggle(e, image.id)}
            >
              <HeartIcon filled={image.isLiked} />
            </div>
          )}
          <div className="absolute bottom-2 right-2 bg-white bg-opacity-75 rounded-full px-2 py-1">
            <span className="text-xs font-bold">{image.likeCount}</span>
          </div>
        </div>
      )}
    </div>
  );
});

const ImageMosaic = ({ initialImages, totalCount, formToken, signedIn }) => {
  const [images, setImages] = useState(initialImages || []);
  const [offset, setOffset] = useState(initialImages ? initialImages.length : 0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const loadMoreImages = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await axios.get('/public_images.json', {
        params: { offset: offset, per_page: 12 },
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      });

      const { chat_images, has_more } = response.data;
      
      if (chat_images && chat_images.length > 0) {
        setImages(prevImages => [...prevImages, ...chat_images]);
        setOffset(prevOffset => prevOffset + chat_images.length);
        setHasMore(has_more);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error loading images:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, offset]);

  useEffect(() => {
    if (inView) {
      loadMoreImages();
    }
  }, [inView, loadMoreImages]);

  const openLightbox = useCallback((image) => {
    setSelectedImage(image);
  }, []);

  const closeLightbox = useCallback(() => {
    setSelectedImage(null);
  }, []);

  const handleHeartToggle = useCallback(async (e, imageId) => {
    e.stopPropagation();
    try {
      const response = await axios.post(`/api/v1/chat_images/${imageId}/toggle_like`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      });
      if (response.status === 200) {
        const updatedImageData = response.data.chat_image;
        setImages(prevImages => prevImages.map(img => 
          img.id === imageId ? { ...img, ...updatedImageData } : img
        ));
      }
    } catch (error) {
      console.error('Error toggling heart:', error);
    }
  }, []);

  return (
    <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {images.map(image => (
          <ImageCell
            key={image.id}
            image={image}
            onClick={openLightbox}
            onHeartToggle={handleHeartToggle}
            signedIn={signedIn}
          />
        ))}
        {hasMore && Array.from({ length: 4 }).map((_, index) => (
          <ImagePlaceholder key={`placeholder-${index}`} />
        ))}
      </div>
      {hasMore && (
        <div ref={ref} className="py-10 text-center text-gray-500">
          {loading ? 'Loading more images...' : 'Scroll down for more images...'}
        </div>
      )}
      {selectedImage && <Lightbox image={selectedImage} onClose={closeLightbox} />}
    </div>
  );
};

export default React.memo(ImageMosaic);